<template>
  <form @submit.prevent="submit" class="mx-auto space-y-4 text-left">
    <div>
      <div class="border border-white rounded flex flex-col py-1.5 px-2 ">
        <label for="name" class="text-sm text-gray-100">Name <span class="text-gray-100">(Required)</span></label>
        <input v-model="fields.name" id="name" type="text" class="text-white bg-transparent focus:outline-none" />
      </div>
      <div v-if="errors && errors.name" class="text-red-900">{{ errors.name[0] }}</div>
    </div>
    <div>
      <div class="border border-white rounded flex flex-col py-1.5 px-2 ">
        <label for="email" class="text-sm text-gray-100">E-Mail <span class="text-gray-100">(Required)</span></label>
        <input v-model="fields.email" id="email" type="email" class="text-white bg-transparent focus:outline-none" />
      </div>
      <div v-if="errors && errors.email" class="text-red-900">{{ errors.email[0] }}</div>
    </div>
    <div>
      <div class="border border-white rounded flex flex-col py-1.5 px-2">
        <label for="contact_number" class="text-sm text-gray-100"
          >Contact Number <span class="text-gray-100">(Required)</span></label
        >
        <input v-model="fields.contact" id="contact_number" type="tel" class="text-white bg-transparent focus:outline-none" />
      </div>
      <div v-if="errors && errors.contact" class="text-red-900">{{ errors.contact[0] }}</div>
    </div>
    <div>
      <div class="border border-white rounded flex flex-col py-1.5 px-2">
        <label for="message" class="text-sm text-gray-100">Message</label>
        <textarea
          v-model="fields.message"
          id="message"
          rows="4"
          type="text"
          class="h-auto p-0 px-0 m-0 font-sans text-base text-white bg-transparent border-0 focus:outline-none"
        ></textarea>
      </div>
      <!-- <div v-if="errors && errors.name" class="text-red-900">{{ errors.name[0] }}</div> -->
    </div>
    <div>
      <div class="">
        <div recaptcha-el></div>
        <div class="text-red-900">{{ recaptchaError }}</div>
      </div>
      <br>
      <button
        v-text="busy ? 'Submitting...' : 'Submit'"
        type="submit"
        class="w-full py-2 text-lg font-medium transition bg-white border-none rounded text-blue-1 opacity-90 hover:opacity-100 disabled:cursor-wait disabled:opacity-50"
      ></button>

    </div>
  </form>
</template>

<script>
import hapiMixins from 'hapiform-with-recaptcha';

export default {
  mixins: [hapiMixins],
  // components: { InputError },
  data() {
    return {
      endpoint: "https://hapiform.sg/api/15c6fe3a-f3f3-4a5e-b629-2ca256254a24",
      redirectTo: "/thank-you",
      recaptchaDisabled: false
    };
  },
};
</script>
