<template>
  <div>
    <WaBtn />
    <MainBanner />

    <!-- product  -->
    <div id="our_services" class="py-10">
      <div class="container">
        <h1 class="text-2xl text-center text-gray-900 lg:text-3xl">
          Extensive <span class="text-red-600">Expertise</span> In The Carpet
          Industry
        </h1>
        <p class="pt-4 text-base leading-tight text-center text-gray-600">
          We Have Variety of Quality Carpet Design to Meet Your Needs
        </p>

        <div class="pt-10 md:flex md:flex-wrap ">
          <div v-for="(item, i) in product" :key="i" class="pb-10 md:w-1/2 lg:w-1/3 md:px-1">
            <div class="relative z-0">
              <img :src="'/images/' + item.image + '.jpg'" :alt="item.alt" />
              <div class="absolute left-0 right-0 w-10/12 mx-auto -bottom-5">
                <p
                  class="py-2 text-lg font-semibold text-center text-white transition duration-300 ease-linear bg-red-600 hover:bg-gray-900">
                  {{ item.alt }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- why choose us -->
    <div id="why_choose_us" class="py-10 bg-center bg-no-repeat bg-cover"
      style="background-image: url('/images/bg-choose.jpg')">
      <div class="container">
        <h1 class="text-2xl text-center text-white lg:text-3xl">Why Choose Us</h1>

        <div class="pt-8 xl:max-w-5xl xl:mx-auto">
          <div class="md:w-2/3 lg:w-full lg:flex lg:flex-wrap md:mx-auto">
            <div v-for="(item, i) in choose_us" :key="i" class="pb-5 lg:w-1/2">
              <div class="md:flex md:items-start">
                <div
                  class="w-24 h-24 p-2 mx-auto transition duration-300 ease-linear border-2 border-red-500 rounded-full hover:bg-red-500 md:mx-0">
                  <img :src="'/images/' + item.image + '.png'" :alt="item.alt" class="w-16 pt-1 mx-auto" />
                </div>
                <div class="pt-3 text-center text-white md:w-4/5 lg:w-5/6 md:text-left md:pl-4">
                  <h1 class="text-xl leading-tight">{{ item.alt }}</h1>
                  <p class="pt-2 text-base">{{ item.p }}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- sub banner -->
    <div class="py-10">
      <div class="container">
        <div class="md:flex md:items-center xl:max-w-5xl xl:mx-auto">
          <div class="mb-4 md:w-1/2">
            <p class="pb-2 text-2xl text-gray-600 lg:text-3xl">
              Udani’s Carpet
            </p>
            <h1 class="text-5xl text-gray-900 lg:text-6xl">
              The <span class="text-red-600">#1 Carpet Wholesaler</span> in
              Malaysia
            </h1>

            <div class="mt-6">
              <p class="w-64 py-1 text-center text-white bg-red-500 rounded-md">
                <a href="https://wa.me/60125008471">GET FREE QUOTE TODAY!</a>
              </p>
            </div>
          </div>
          <div class="mt-10 shadow-md md:w-1/2 lg:w-1/3 lg:mx-auto">
            <img src="/images/banner-1.jpg" alt="" class="" />
          </div>
        </div>
      </div>
    </div>

    <!-- operate -->
    <div class="py-10">
      <div class="container">
        <div class="md:flex md:flex-wrap lg:max-w-4xl lg:mx-auto">
          <div v-for="(item, i) in sub_info" :key="i" class="pb-5 md:px-2 md:w-1/3">
            <div class="w-3/4 p-6 mx-auto shadow-lg md:w-full">
              <img :src="'/images/' + item.image + '.png'" :alt="item.alt" class="w-20 mx-auto" />
              <div class="mt-6 text-center">
                <p class="text-base font-semibold text-red-600 md:leading-tight md:h-14 lg:h-auto">
                  {{ item.alt }}
                </p>
                <p class="pt-4 text-base leading-tight text-gray-600 md:h-32 lg:h-28">
                  {{ item.p }}
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- about us -->
    <div id="about_us" class="py-10">
      <div class="container">
        <div class="md:flex md:flex-wrap md:items-center">
          <div class="md:w-1/2 md:pr-4">
            <p class="text-lg font-medium text-gray-900 lg:text-2xl">
              About Us
            </p>
            <h1 class="text-4xl font-semibold text-red-500">Udani Carpets</h1>
            <div class="bg-blue-1 w-12 mt-3 h-0.5"></div>
            <p class="pt-6 text-base leading-tight text-gray-600">
              Udani Carpets Sdn Bhd formally known as Udani Carpets &
              Furnishing, one of the most exclusive carpet’s showrooms in
              Malaysia was established in 1977 by Mr. RB Udani and Mr. Ashok
              Udani together with their new joined in third generation Mr. Dhruv
              Udani. <br /><br />
              Udani Carpets offers high quality imported carpets and rugs,
              promise to deliver highest customer’s satisfaction and fulfill
              high standard flooring arts. The company’s continuous growth
              strategies over the year have transformed it to an employee of
              over 20 workers.
            </p>
          </div>
          <div class="mt-4 md:w-1/2 lg:w-1/3 lg:mx-auto md:mt-0">
            <img src="/images/about-us.png" alt="" class="" />
          </div>
        </div>
      </div>
    </div>

    <!-- gallery -->
    <div class="py-10 bg-gray-50">
      <div class="container">
        <h1 class="text-2xl font-semibold text-center text-gray-900 lg:text-3xl">
          Our <span class="text-red-600">Gallery</span>
        </h1>
        <div class="bg-blue-1 w-12 mx-auto mt-3 h-0.5"></div>

        <div class="flex flex-wrap mt-6">
          <div v-for="image in gallery" :key="image" class="w-1/2 p-1 lg:w-1/5 md:w-1/3">
            <img :src="'/images/' + image + '.jpg'" alt=""
              class="transition duration-300 transform md:w-full hover:scale-110 hover:shadow-md" />
          </div>
        </div>
      </div>
    </div>

    <Testimonials />

    <!-- footer -->
    <div id="element" class="py-10 bg-gray-800">
      <div class="container lg:flex lg:items-center">
        <div class="md:w-2/3 md:mx-auto lg:w-1/2 lg:order-2">
          <!-- enquiry form  -->
          <div class="p-6 bg-red-600 rounded-md bg-opacity-90">
            <div class="text-center">
              <p class="text-3xl font-semibold text-white">Send an Enquiry</p>
              <div class="bg-white w-14 h-0.5 mx-auto my-3"></div>
              <p class="pt-3 text-sm text-gray-100">
                Fill out the form below and we'll get back to you as soon as
                possible
              </p>
            </div>
            <EnquiryForm class="pt-4" />
          </div>
        </div>
        <div class="pt-5 lg:w-1/2 lg:pr-4">
          <img src="/images/main-logo.png" alt="" class="w-2/3 mx-auto" />
          <div class="pt-3 text-center text-white">
            <div class="pb-5">
              <img src="/images/img-hq.jpg" alt="" class="w-1/2 mx-auto">
              <h1 class="pb-2 text-xl font-medium">
                Udani Carpets Sdn Bhd (HQ)
              </h1>
              <p class="text-base font-normal leading-tight md:text-xl">
                393, Jalan Tuanku Abdul Rahman, 50100 Kuala Lumpur
              </p>
              <div class="pt-5">
                <img src="/images/img-warehouse.jpg" alt="" class="w-1/2 mx-auto">
                <h1 class="pb-2 text-xl font-medium">
                  Udani Carpets Sdn Bhd (Warehouse)
                </h1>
                <p class="text-base font-normal leading-tight md:text-xl">
                  1554, Jalan Kusta, Kampung Melayu Batu 13, 47000 Sungai Buloh, Selangor, Malaysia
                </p>
              </div>
              <div class="pt-5">
                <p class="font-bold">Message Us on WhatsApp</p>
                <p class="font-medium transition duration-300 transform hover:scale-110 hover:text-blue-1">
                  <a href="https://wa.me/60125008471" class="">
                    012-500 8471</a>
                </p>
              </div>

              <div class="pt-4 font-medium">
                <p class="font-bold">Call Us</p>
                <p class="font-medium transition duration-300 transform hover:scale-110 hover:text-red-600">
                  Office : <a href="tel:+60326984257">03-26984257</a>
                </p>
                <p class="font-medium transition duration-300 transform hover:scale-110 hover:text-red-600">
                  Mobile : <a href="tel:+60125008471">012-500 8471</a>
                </p>
              </div>
              <div class="pt-4 font-medium">
                <p class="font-bold">Operating Hours</p>
                <p>Monday – Friday: 9:30am – 6:30pm</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="py-3 bg-black">
      <div class="container mx-auto">
        <h1 class="text-xs text-center text-white lg:text-sm">
          Website maintained by Activa Media. All Rights Reserved.
        </h1>
      </div>
    </div>
  </div>
</template>

<script>
import MainBanner from "@/components/MainBanner.vue"
import Testimonials from "@/components/Testimonials.vue";
import EnquiryForm from "@/components/EnquiryForm.vue";
import WaBtn from "@/components/WaBtn.vue"

export default {
  components: {
    Testimonials,
    EnquiryForm,
    MainBanner,
    WaBtn
  },
  data() {
    return {
      product: [
        { image: "product-1", alt: "Office Carpet" },
        { image: "product-2", alt: "Hotel Carpet" },
        { image: "product-3", alt: "Carpet Tiles" },
        { image: "product-4", alt: "Broadloom Carpet" },
        { image: "product-5", alt: "Exhibition Carpet " },
        { image: "product-6", alt: "Mosque Carpet" },
      ],
      choose_us: [
        {
          image: "icon-1",
          alt: "Quality Assurance",
          p: "We ensure and assure high quality carpets",
        },
        {
          image: "icon-2",
          alt: "100% Customer Satisfaction Guarantee ",
          p: "Our customer satisfaction is our top priority",
        },
        {
          image: "icon-3",
          alt: "Free Professional Consultation & Installation",
          p: "We provide non-obligation consultation and free installation services",
        },
        {
          image: "icon-4",
          alt: "30 Days warranty",
          p: "We offer 30 days warranty",
        },
        {
          image: "icon-5",
          alt: "Reasonable Price",
          p: "We provide the best service and customize according to client’s budget",
        },
      ],
      sub_info: [
        {
          image: "sub-1",
          alt: "Direct From Factory",
          p: "Direct factory price. To provide wide range of carpets for office, event, hotel, exhibition, home and etc.",
        },
        {
          image: "sub-2",
          alt: "Exclusive Carpet Showroom",
          p: "Buy best carpet in town and explore the finest quality materials.",
        },
        {
          image: "sub-3",
          alt: "Full Customization",
          p: "We cater to your preferred size and length.",
        },
      ],
      gallery: [
        "gallery-1",
        "gallery-2",
        "gallery-3",
        "gallery-4",
        "gallery-5",
        "gallery-6",
        "gallery-7",
        "gallery-8",
        "gallery-9",
        "gallery-10",
      ],
    };
  },
};
</script>
