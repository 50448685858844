<template>
  <div
    class="bg-center bg-no-repeat bg-cover"
    style="background-image: url('/images/bg-banner.jpg')"
  >
    <!-- header  -->
    <div v-if="!hideEnquiry" class="relative z-10">
      <div class="container">
        <TopHeader />
      </div>
    </div>

    <!-- banner  -->
    <div class="py-52">
      <div class="container">
        <div class="md:w-2/3 lg:w-1/2">
          <div class="px-4 py-8 text-white bg-black bg-opacity-50">
            <!-- <p class="text-lg leading-tight lg:text-xl">
              Professional Carpet Specialist
            </p> -->
            <h1 class="py-2 text-3xl leading-tight lg:text-5xl md:text-4xl">
              No.1
              <span class="text-yellow-300">Carpet Supplier</span> in Malaysia
            </h1>
            <p
              class="font-medium leading-tight md:text-lg lg:text-base md:pt-4"
            >
            Over 45 years of experience supplying for Offices, Hotels, Exhibitions etc.
            </p>

            <div v-if="!hideEnquiry" class="mt-3">
              <p
                class="w-48 py-1 text-center transition duration-300 transform bg-red-700 rounded-md hover:scale-110"
              >
                <a href="https://wa.me/60125008471">Get Your Free Quote</a>
              </p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import TopHeader from "@/components/TopHeader.vue";

export default {
  components: {
    TopHeader,
  },
  props: {
    hideEnquiry: {
      type: Boolean,
      default: false,
    },
  },
};
</script>
